<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Opportunity Info">
        <FormRow
          required
          label="Title"
          tooltip="This is the title of the Submission Form as it will be displayed on the front-end of your site."
        >
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
        <FormRow label="Description">
          <template #tooltip>
            Add any finer detail to be displayed on the front-end of your site
            under the "title."
          </template>
          <TextArea
            placeholder="Description"
            v-model="form.description"
          ></TextArea>
        </FormRow>
        <div v-if="currentUser.active_project.slug != 'referral'">
          <FormRow
            label="Allow Anonymous"
            tooltip="Tick this box if you want anyone to be able to submit the form."
          >
            <Checkbox placeholder="Allow Anonymous" v-model="form.anon" />
          </FormRow>
        </div>
        <FormRow label="Submission Button Text">
          <template #tooltip>
            This is the button at the bottom of the form a user clicks to
            submit.
          </template>
          <TextField v-model="form.btn_text"></TextField>
        </FormRow>

        <!-- <FormRow>
          <template #label>
            Preview Image<br />
            (240x240)
          </template>
          <Uploader :ratio="240 / 240" v-model="form.preview" enableCropper />
        </FormRow> -->

        <FormRow label="Success Message">
          <template #tooltip>
            This message will be displayed to an applicant after they have
            submitted their submission.
          </template>
          <TextArea
            placeholder="Success Message"
            v-model="form.success_message"
          ></TextArea>
        </FormRow>
        <FormRow label="Post-Submission Text">
          <template #tooltip>
            This message will be displayed to an applicant after submission
            change state
          </template>
          <TextArea
            placeholder="Post-Submission Text"
            v-model="form.post_submission_text"
          ></TextArea>
        </FormRow>

        <FormRow
          label="Display Page"
          tooltip="If your Opportunity has multiple pages, like an EVP, select which page you wish your submission form to display on."
        >
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
      </FormPanel>

      <FormPanel required label="Eligibility Requirements">
        <FormRow
          label="Badges"
          tooltip="Restricts submissions to users with at least 1 of the selected badges. If no badge types are selected, the Opportunity will be open to All Community Creators."
        >
          <BadgeSelector multiple v-model="form.eligibility_ids" />
        </FormRow>

        <FormRow label="Uneligible Creator Message">
          <template #tooltip>
            This message will be displayed to applicants if they don't fit
            Eligibility Requirements
          </template>
          <TextArea
            placeholder="Uneligible Creator Message"
            v-model="form.dont_fit"
          ></TextArea>
        </FormRow>
      </FormPanel>
      <FormPanel required label="Creator Submission Form Builder">
        <FormBuilder v-model="form.submission_form" reviewOptions />
      </FormPanel>
      <!-- <FormPanel
        label="UGC Section Details"
        tooltip="Select the page or pages on which you'd like this Submission Form to appear."
      >
        <FormRow
          label="Display Page"
          tooltip="If your Opportunity has multiple pages, like an EVP, select which page you wish your submission form to display on."
        >
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
      </FormPanel> -->
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";
export default {
  extends: mixin,
};
</script>

<style></style>
